import UtilsUser from "../utils/user";

export default class Header {
    constructor() {
        (document.querySelectorAll(".ticket_queue_favorite") as NodeListOf<HTMLElement>).forEach((elem: any) => {
            elem.addEventListener("click", async (e: any) => {
                e.preventDefault()
                const uuid = elem.getAttribute("data-id") as string
                if (elem.classList.contains("ti-star")) {
                    //new fav
                    await UtilsUser.addTicketQueueFavorite(uuid)
                    elem.classList.remove("ti-star")
                    elem.classList.add("ti-star-filled")
                } else {
                    await UtilsUser.removeTicketQueueFavorite(uuid)
                    elem.classList.remove("ti-star-filled")
                    elem.classList.add("ti-star")
                }

            })
        });
        (document.querySelectorAll(".nav_add_link") as NodeListOf<HTMLElement>).forEach((elem: any) => {
            elem.addEventListener("click", async (e: any) => {
                e.preventDefault()
                const url = elem.getAttribute("href")
                document.location.href = url;
            })
        });
    }
}
